import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setSelectedBusiness, useGetBusinessByIdQuery } from '../store';

const useGetSelectedBusiness = () => {
  const dispatch = useDispatch();
  const { business_id: businessId } = useParams<{ business_id: string }>();
  const selectedBusiness = useSelector((state: RootState) => state.businesses.business);
  const { data: business, isLoading: businessLoading } = useGetBusinessByIdQuery(+(businessId ?? 0), { skip: !businessId || !!selectedBusiness });
  if (business && !selectedBusiness) {
    dispatch(setSelectedBusiness(business));
  }
  return { business: business || selectedBusiness, businessLoading };
};

export default useGetSelectedBusiness;
