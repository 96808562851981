import { ErrorResponse } from '../models/shared';

export enum ErrorCode {
  TokenInvalid = 'TokenInvalid',
  InvalidBusinessId = 'InvalidBusinessId',
  UserHasNoBusiness = 'UserHasNoBusiness',
  UserDoesNotHavePermissionsForBusiness = 'UserDoesNotHavePermissionsForBusiness',
  UserDoesNotHavePermissionsForEvent = 'UserDoesNotHavePermissionsForEvent',
  ItemDoesNotHaveTicket = 'ItemDoesNotHaveTicket',
  OrderDoesNotHaveItems = 'OrderDoesNotHaveItems',
  OrderDoesNotHavePayments = 'OrderDoesNotHavePayments',
  OrderDoesNotHavePaymentToReimburse = 'OrderDoesNotHavePaymentToReimburse',
  OrderDoesNotHavePendingPayment = 'OrderDoesNotHavePendingPayment',
  OrderIdRequired = 'OrderIdRequired',
  VenueSeatNotFound = 'VenueSeatNotFound',
  EventSeatNotFound = 'EventSeatNotFound',
  EventSectorDoesNotBelongToEvent = 'EventSectorDoesNotBelongToEvent',
  EventHasNoVenue = 'EventHasNoVenue',
  UnknownError = 'UnknownError',
  UserNotFound = 'UserNotFound',
  EventNotFound = 'EventNotFound',
  OrderNotFound = 'OrderNotFound',
  TicketNotFound = 'TicketNotFound',
  ItemNotFound = 'ItemNotFound',
  PaymentNotFound = 'PaymentNotFound',
  AgentNotFound = 'AgentNotFound',
  DecodeError = 'DecodeError',
  NoPaidItemsFound = 'NoPaidItemsFound',
  TicketItemsMismatch = 'TicketItemsMismatch',
  NoTicketsLeft = 'NoTicketsLeft',
  MaxTicketsPerOrderExceeded = 'MaxTicketsPerOrderExceeded',
  TicketInactive = 'TicketInactive',
  NoCustomPrice = 'NoCustomPrice',
  InvalidCustomPrice = 'InvalidCustomPrice',
  InvalidCouponEvent = 'InvalidCouponEvent',
  UserAlreadyInvited = 'UserAlreadyInvited',
  UserAlreadyInBusiness = 'UserAlreadyInBusiness',
  InvitationNotFound = 'InvitationNotFound',
  CodeUsed = 'CodeUsed',
  InvalidOrderStatusTransition = 'InvalidOrderStatusTransition',
  ItemOtherEvent = 'ItemOtherEvent',
  ItemDoesNotHaveOrder = 'ItemDoesNotHaveOrder',
  SeatsHaveNoReservationCode = 'SeatsHaveNoReservationCode',
  ReservationNotFound = 'ReservationNotFound',
  ItemDoesNotHaveSeatOrTicket = 'ItemDoesNotHaveSeatOrTicket',
  OrderWithNoItems = 'OrderWithNoItems',
  SectorDoesNotMatch = 'SectorDoesNotMatch',
  SeatReservationMismatch = 'SeatReservationMismatch',
  SeatNotAvailable = 'SeatNotAvailable',
  VerificationCodeIncorrect = 'VerificationCodeIncorrect',
  BusinessNotFound = 'BusinessNotFound',
  CouponNotFound = 'CouponNotFound',
  ReservationExpired = 'ReservationExpired',
  EventSectorNotFound = 'EventSectorNotFound',
  MercadoPagoPreferenceCreationFailed = 'MercadoPagoPreferenceCreationFailed',
  MercadoPagoPaymentRetrievalFailed = 'MercadoPagoPaymentRetrievalFailed',
  UalaTokenRetrievalFailed = 'UalaTokenRetrievalFailed',
  UalaPaymentCreationFailed = 'UalaPaymentCreationFailed',
  PollResponsesNotFound = 'PollResponsesNotFound',
  ReferralNotFound = 'ReferralNotFound',
  SeatReservationNotFound = 'SeatReservationNotFound',
  FirebaseIdRequired = 'FirebaseIdRequired',
  CodeRequired = 'CodeRequired',
  PdfGenerationFailed = 'PdfGenerationFailed',
  TicketMailGenerationFailed = 'TicketMailGenerationFailed',
  VenueNotFound = 'VenueNotFound',
  Unauthorized = 'Unauthorized',
  UserEventNotFound = 'UserEventNotFound',
  UserBusinessNotFound = 'UserBusinessNotFound',
  UserDoesNotHavePermissionsForSeller = 'UserDoesNotHavePermissionsForSeller',
  SellerDoesNotHaveSummary = 'SellerDoesNotHaveSummary',
  EventTransactionNotFound = 'EventTransactionNotFound',
  NoBasePrice = 'NoBasePrice',
  ItemNotPaid = 'ItemNotPaid',
  OrderNotPaid = 'OrderNotPaid',
  FromBackofficeNotAllowed = 'FromBackofficeNotAllowed',
  InvalidSellerQrCode = 'InvalidSellerQrCode',
  UserEventDoesNotBelongToEvent = 'UserEventDoesNotBelongToEvent',
  EventDoesNotHaveSellerQr = 'EventDoesNotHaveSellerQr',
  TicketPrivileged = 'TicketPrivileged',
  NotImplemented = 'NotImplemented',
  PaymentConfigNotFound = 'PaymentConfigNotFound',
  MercadoPagoAccessTokenCreationFailed = 'MercadoPagoAccessTokenCreationFailed',
  MercadoPagoAlreadyAuthorized = 'MercadoPagoAlreadyAuthorized',
}

const errorMessages: Record<ErrorCode, string> = {
  [ErrorCode.TokenInvalid]: 'Su sesión ha expirado',
  [ErrorCode.InvalidBusinessId]: 'ID de negocio inválido',
  [ErrorCode.UserHasNoBusiness]: 'El usuario no tiene un negocio asociado',
  [ErrorCode.UserDoesNotHavePermissionsForBusiness]: 'No tenés permisos para este negocio',
  [ErrorCode.UserDoesNotHavePermissionsForEvent]: 'No tenés permisos para este evento',
  [ErrorCode.ItemDoesNotHaveTicket]: 'El ítem no tiene una entrada asociada',
  [ErrorCode.OrderDoesNotHaveItems]: 'La orden no tiene ítems',
  [ErrorCode.OrderDoesNotHavePayments]: 'La orden no tiene pagos registrados',
  [ErrorCode.OrderDoesNotHavePaymentToReimburse]: 'La orden no tiene pagos para reembolsar',
  [ErrorCode.OrderDoesNotHavePendingPayment]: 'La orden no tiene pagos pendientes',
  [ErrorCode.OrderIdRequired]: 'Se requiere el ID de la orden',
  [ErrorCode.VenueSeatNotFound]: 'Asiento no encontrado',
  [ErrorCode.EventSeatNotFound]: 'Asiento del evento no encontrado',
  [ErrorCode.EventSectorDoesNotBelongToEvent]: 'El sector no pertenece a este evento',
  [ErrorCode.EventHasNoVenue]: 'El evento no tiene sede asignada',
  [ErrorCode.UnknownError]: 'Error desconocido',
  [ErrorCode.UserNotFound]: 'Usuario no encontrado',
  [ErrorCode.EventNotFound]: 'Evento no encontrado',
  [ErrorCode.OrderNotFound]: 'Orden no encontrada',
  [ErrorCode.TicketNotFound]: 'Entrada no encontrada',
  [ErrorCode.ItemNotFound]: 'Ítem no encontrado',
  [ErrorCode.PaymentNotFound]: 'Pago no encontrado',
  [ErrorCode.AgentNotFound]: 'Agente no encontrado',
  [ErrorCode.DecodeError]: 'Código inválido',
  [ErrorCode.NoPaidItemsFound]: 'DNI buscado no tiene entradas registradas',
  [ErrorCode.TicketItemsMismatch]: 'Los ítems no coinciden con las entradas',
  [ErrorCode.NoTicketsLeft]: 'No quedan entradas disponibles',
  [ErrorCode.MaxTicketsPerOrderExceeded]: 'Se excedió el máximo de entradas por orden',
  [ErrorCode.TicketInactive]: 'La entrada no está activa',
  [ErrorCode.NoCustomPrice]: 'No hay precio personalizado',
  [ErrorCode.InvalidCustomPrice]: 'Precio personalizado inválido',
  [ErrorCode.InvalidCouponEvent]: 'Cupón inválido para este evento',
  [ErrorCode.UserAlreadyInvited]: 'El usuario ya fue invitado',
  [ErrorCode.UserAlreadyInBusiness]: 'El usuario ya pertenece al negocio',
  [ErrorCode.InvitationNotFound]: 'Invitación no encontrada',
  [ErrorCode.CodeUsed]: 'Código ya utilizado',
  [ErrorCode.InvalidOrderStatusTransition]: 'Transición de estado de orden inválida',
  [ErrorCode.ItemOtherEvent]: 'La entrada pertenece a otro evento',
  [ErrorCode.ItemDoesNotHaveOrder]: 'El ítem no tiene una orden asociada',
  [ErrorCode.SeatsHaveNoReservationCode]: 'Los asientos no tienen código de reserva',
  [ErrorCode.ReservationNotFound]: 'Reserva no encontrada',
  [ErrorCode.ItemDoesNotHaveSeatOrTicket]: 'El ítem no tiene asiento o entrada asignada',
  [ErrorCode.OrderWithNoItems]: 'Orden sin ítems',
  [ErrorCode.SectorDoesNotMatch]: 'El sector no coincide',
  [ErrorCode.SeatReservationMismatch]: 'La reserva del asiento no coincide',
  [ErrorCode.SeatNotAvailable]: 'Asiento no disponible',
  [ErrorCode.VerificationCodeIncorrect]: 'Código de verificación incorrecto',
  [ErrorCode.BusinessNotFound]: 'Negocio no encontrado',
  [ErrorCode.CouponNotFound]: 'Cupón no encontrado',
  [ErrorCode.ReservationExpired]: 'La reserva ha expirado',
  [ErrorCode.EventSectorNotFound]: 'Sector del evento no encontrado',
  [ErrorCode.MercadoPagoPreferenceCreationFailed]: 'Error al crear la preferencia de MercadoPago',
  [ErrorCode.MercadoPagoPaymentRetrievalFailed]: 'Error al obtener el pago de MercadoPago',
  [ErrorCode.UalaTokenRetrievalFailed]: 'Error al obtener el token de Ualá',
  [ErrorCode.UalaPaymentCreationFailed]: 'Error al crear el pago en Ualá',
  [ErrorCode.PollResponsesNotFound]: 'Respuestas de la encuesta no encontradas',
  [ErrorCode.ReferralNotFound]: 'Referido no encontrado',
  [ErrorCode.SeatReservationNotFound]: 'Reserva de asiento no encontrada',
  [ErrorCode.FirebaseIdRequired]: 'Se requiere ID de Firebase',
  [ErrorCode.CodeRequired]: 'Se requiere un código',
  [ErrorCode.PdfGenerationFailed]: 'Error al generar el PDF',
  [ErrorCode.TicketMailGenerationFailed]: 'Error al generar el correo de la entrada',
  [ErrorCode.VenueNotFound]: 'Sede no encontrada',
  [ErrorCode.Unauthorized]: 'No autorizado',
  [ErrorCode.UserEventNotFound]: 'Evento del usuario no encontrado',
  [ErrorCode.UserBusinessNotFound]: 'Negocio del usuario no encontrado',
  [ErrorCode.UserDoesNotHavePermissionsForSeller]: 'No tenés permisos para este vendedor',
  [ErrorCode.SellerDoesNotHaveSummary]: 'El vendedor no tiene resumen',
  [ErrorCode.EventTransactionNotFound]: 'Transacción no encontrada',
  [ErrorCode.NoBasePrice]: 'No hay precio base',
  [ErrorCode.ItemNotPaid]: 'El ítem no está pagado',
  [ErrorCode.OrderNotPaid]: 'La orden no está pagada',
  [ErrorCode.FromBackofficeNotAllowed]: 'No se puede acceder desde el backoffice',
  [ErrorCode.InvalidSellerQrCode]: 'Código de vendedor inválido',
  [ErrorCode.UserEventDoesNotBelongToEvent]: 'El evento del usuario no pertenece al evento',
  [ErrorCode.EventDoesNotHaveSellerQr]: 'El evento no tiene código de vendedor',
  [ErrorCode.TicketPrivileged]: 'La entrada es privilegiada',
  [ErrorCode.NotImplemented]: 'No implementado',
  [ErrorCode.PaymentConfigNotFound]: 'Configuración de pago no encontrada',
  [ErrorCode.MercadoPagoAccessTokenCreationFailed]: 'Error al crear el token de MercadoPago',
  [ErrorCode.MercadoPagoAlreadyAuthorized]: 'MercadoPago ya autorizado',
};

const parseErrors = (errorData: ErrorResponse) => {
  const code = errorData.code as keyof typeof errorMessages;
  return errorMessages[code] || errorData.error;
};

export default parseErrors;
