import { useEffect, useState } from 'react';
import { useAuthorizeMercadoPagoMutation, useGetBusinessByIdQuery } from '../store';
import getErrorResponse from '../utils/getErrorResponse';
import { ErrorCode } from '../utils/parseErrors';
import useQuery from '../hooks/useQuery';
import LoadingLottie from '../components/LoadingLottie';

const MPOAuthRedirectPage: React.FC = () => {
  const query = useQuery();
  const [code, setCode] = useState<string | null>(null);
  const [businessId, setBusinessId] = useState<string | null>(null);
  const { data: business, isLoading: isLoadingBusiness } = useGetBusinessByIdQuery(+(businessId || 0), { skip: !businessId });
  const [authorizeMercadoPago, resultsAuth] = useAuthorizeMercadoPagoMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    if (isLoadingBusiness || resultsAuth.isLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingBusiness, resultsAuth.isLoading]);

  useEffect(() => {
    setCode(query.get('code') || null);
    setBusinessId(query.get('state') || null);
  }, [query]);

  useEffect(() => {
    if (business?.id && code) {
      authorizeMercadoPago({ businessId: business.id, authCode: code });
    }
  }, [authorizeMercadoPago, business, code]);

  // Handle error states
  useEffect(() => {
    if (!code) {
      setErrorMsg('No se encontró el código o el id de la empresa');
      return;
    }

    if (!business && !isLoadingBusiness && businessId) {
      setErrorMsg('No se encontró la productora');
      return;
    }

    if (resultsAuth.isError) {
      const error = getErrorResponse(resultsAuth.error);
      if (error.code === ErrorCode.MercadoPagoAlreadyAuthorized) {
        setErrorMsg('MercadoPago ya autorizado');
      } else {
        setErrorMsg('Error al autorizar');
      }
    }
  }, [code, business, isLoadingBusiness, businessId, resultsAuth.isError, resultsAuth.error]);

  return (
    <div className="p-5">
      <h1 className="text-xl text-center">Autorización de Mercado Pago</h1>
      <img src="/logo_mp_hor.png" className="mr-3" alt="Mercado Pago logo" />
      {isLoading && <LoadingLottie />}
      {!isLoading && (
        <div className="flex justify-center items-center text-xl my-5">
          {resultsAuth.isSuccess && <div className="text-green-500">Integración exitosa</div>}
          {resultsAuth.isError && <div className="text-red-500">{errorMsg}</div>}
        </div>
      )}
    </div>
  );
};

export default MPOAuthRedirectPage;
