import { useEffect, useState } from 'react';
import Button from '../components/Button';
import LoadingLottie from '../components/LoadingLottie';
import useGetSelectedBusiness from '../hooks/useGetSelectedBusiness';
import { PaymentProvider } from '../models/payment-config';
import { useGetMPAuthLinkQuery, useGetPaymentConfigQuery } from '../store';

const MercadoPagoIntegrationPage: React.FC = () => {
  const { business, businessLoading } = useGetSelectedBusiness();
  const { data: paymentConfig, isLoading: paymentConfigLoading } = useGetPaymentConfigQuery(
    { businessId: business?.id ?? 0, provider: PaymentProvider.MercadoPago },
    { skip: !business },
  );
  const { data: authLink, isLoading: linkLoading } = useGetMPAuthLinkQuery(
    { business_id: business?.id ?? 0 },
    { skip: !business },
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(businessLoading || paymentConfigLoading || linkLoading);
  }, [businessLoading, paymentConfigLoading, linkLoading]);

  const handleIntegrate = () => {
    setIsLoading(true);
    window.open(authLink?.auth_link || '', '_blank');
  };
  if (isLoading) {
    return <LoadingLottie />;
  }
  if (!business && !isLoading) {
    return <div>No se encontró la empresa</div>;
  }
  return (
    <div>
      <h1>Integración con Mercado Pago</h1>
      <img src="/logo_mp_hor.png" className="mr-3" />
      <div className="flex justify-center items-center text-xl my-5">
        {paymentConfig && <div className="text-green-500">Integrado</div>}
        {!paymentConfig && <div className="text-red-500">No integrado</div>}
      </div>
      {!paymentConfig && (
        <div className="flex justify-center items-center text-xl">
          <Button primary loading={linkLoading} onClick={handleIntegrate}>
            Integrar
          </Button>
        </div>
      )}
    </div>
  );
};

export default MercadoPagoIntegrationPage;
