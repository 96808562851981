import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ItemDTO, OrderDTO, OrderTotalsDTO, SellerSummaryDTO,
} from '../../models/order';
import { Response } from '../../models/shared';
import TicketDTO from '../../models/ticket';
import baseQueryWithReauth from './base';
import EventDTO, { EventSalesReportDTO } from '../../models/event';
import { PollData } from '../../models/poll';
import { ticketsApi } from './tickets';
import { UserEvent } from '../../models/user';

export interface ItemData {
  name: string;
  last_name: string;
  person_id: string;
  ticket_id: number;
  ticket?: TicketDTO,
}

export interface OrderData {
  email: string;
  instagram_user?: string;
  items: ItemData[];
  open_price_amount?: number;
  coupon_id?: number;
  poll_responses?: PollData[];
  is_manual?: boolean;
  skip_poll?: boolean;
  referral_code?: string | null;
  from_backoffice: boolean;
  is_seller_order: boolean;
}

export interface DecodedItemData {
  item_id: number;
  order_id: number;
  name: string;
  last_name: string;
  person_id: string;
  order_status: string;
  valid: boolean;
  marked_used_by: number;
  used_at: string;
  event_id: number,
  ticket_id: number,
  event?: EventDTO
  ticket?: TicketDTO
}

export interface DecodedSellerQRData {
  user_event: UserEvent;
}

const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['orders', 'qrcode', 'seller_summary'],
  endpoints: (build) => ({
    createOrder: build.mutation<Response<OrderDTO>, { order: OrderData, businessId: number, eventId: number }>({
      query: ({
        order, businessId, eventId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders`,
        method: 'POST',
        body: {
          ...order,
        },
      }),
      invalidatesTags: ['orders', 'seller_summary'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(ticketsApi.util.invalidateTags(['tickets']));
        } catch (e) {
          console.error(e);
        }
      },
    }),
    getEventOrders: build.query<Response<OrderDTO[]>, { businessId: number, eventId: number }>({
      providesTags: ['orders'],
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders`,
        method: 'GET',
      }),
    }),
    getSellerOrders: build.query<Response<OrderDTO[]>, { businessId: number, eventId: number }>({
      providesTags: ['orders'],
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders/sellers/data`,
        method: 'GET',
      }),
    }),
    getOrderByCustomId: build.query<OrderDTO | null, { customId: string }>({
      providesTags: ['orders'],
      query: ({ customId }) => ({
        url: `/orders/custom-id/${customId}`,
        method: 'GET',
      }),
      transformResponse: (response: Response<OrderDTO>): (OrderDTO | null) => response.data || null,
    }),
    getEventOrdersExport: build.query<void, { businessId: number, eventId: number }>({
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders`,
        params: {
          xlsx: true,
        },
        method: 'GET',
      }),
    }),
    decodeQrCode: build.query<(DecodedItemData | null), { data: string | null, personId?: string, eventId: number, businessId: number }>({
      providesTags: ['qrcode'],
      query: ({
        data, eventId, businessId, personId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/decode`,
        params: {
          data: data || undefined,
          person_id: personId,
        },
        method: 'GET',
      }),
      transformResponse: (response: Response<DecodedItemData>): (DecodedItemData | null) => response.data || null,

    }),
    updateOrder: build.mutation<Response<OrderDTO>, { order: Partial<OrderDTO>, businessId: number, eventId: number, orderId: number }>({
      query: ({
        order, businessId, eventId, orderId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders`,
        method: 'PUT',
        body: { ...order, id: orderId },
      }),
      invalidatesTags: ['orders'],
    }),
    updateOrderStatus: build.mutation<Response<OrderDTO>, { newStatus: string, businessId: number, eventId: number, orderId: number }>({
      query: ({
        newStatus, businessId, eventId, orderId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders/${orderId}/status`,
        method: 'PATCH',
        body: { status: newStatus },
      }),
      invalidatesTags: ['orders'],
    }),
    updateOrderItem: build.mutation<Response<ItemDTO>, { item: Partial<ItemDTO>, businessId: number, eventId: number, orderId: number, itemId: number }>({
      query: ({
        item, businessId, eventId, orderId, itemId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders/${orderId}/items`,
        method: 'PUT',
        body: { ...item, id: itemId },
      }),
      invalidatesTags: ['orders'],
    }),
    deleteOrderItem: build.mutation<Response<ItemDTO>, { businessId: number, eventId: number, orderId: number, itemId: number }>({
      query: ({
        businessId, eventId, orderId, itemId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders/${orderId}/items/${itemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['orders', 'seller_summary'],
    }),
    deleteOrder: build.mutation<Response<OrderDTO>, { businessId: number, eventId: number, orderId: number }>({
      query: ({
        businessId, eventId, orderId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders/${orderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['orders', 'seller_summary'],
    }),
    updateUsedOrderItem: build.mutation<Response<ItemDTO>, { businessId: number, eventId: number, orderId: number, itemId: number }>({
      query: ({
        businessId, eventId, orderId, itemId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders/${orderId}/items/${itemId}/used`,
        method: 'PATCH',
      }),
      invalidatesTags: ['qrcode'],
    }),
    sendOrderEmail: build.mutation<Response<void>, { businessId: number, eventId: number, customId: string }>({
      query: ({
        businessId, eventId, customId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders/${customId}/email`,
        method: 'POST',
      }),
    }),
    getSellerSummary: build.query<SellerSummaryDTO[] | null, { businessId: number, eventId: number }>({
      providesTags: ['orders', 'seller_summary'],
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders/sellers/summary`,
        method: 'GET',
      }),
      transformResponse: (response: Response<SellerSummaryDTO[]>): (SellerSummaryDTO[] | null) => response.data || null,
    }),
    getOrderTotals: build.query<OrderTotalsDTO | null, { businessId: number, eventId: number, order: OrderData }>({
      providesTags: ['orders'],
      query: ({ businessId, eventId, order }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders/amounts`,
        method: 'POST',
        body: order,
      }),
      transformResponse: (response: Response<OrderTotalsDTO>): (OrderTotalsDTO | null) => response.data || null,
    }),
    getOneSellerSummary: build.query<SellerSummaryDTO | null, { businessId: number, eventId: number, sellerId: number }>({
      providesTags: ['orders', 'seller_summary'],
      query: ({ businessId, eventId, sellerId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders/sellers/summary/${sellerId}`,
        method: 'GET',
      }),
      transformResponse: (response: Response<SellerSummaryDTO>): (SellerSummaryDTO | null) => response.data || null,
    }),
    getEventOrdersReport: build.query<EventSalesReportDTO | null, { businessId: number, eventId: number }>({
      providesTags: () => [{ type: 'orders' }],
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/orders/report/sales`,
        method: 'GET',
      }),
      transformResponse: (response: Response<EventSalesReportDTO>): (EventSalesReportDTO | null) => response.data || null,
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useGetEventOrdersQuery,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useGetEventOrdersExportQuery,
  useUpdateOrderItemMutation,
  useDeleteOrderItemMutation,
  useUpdateOrderStatusMutation,
  useDecodeQrCodeQuery,
  useUpdateUsedOrderItemMutation,
  useSendOrderEmailMutation,
  useGetOrderByCustomIdQuery,
  useGetSellerOrdersQuery,
  useGetSellerSummaryQuery,
  useGetOneSellerSummaryQuery,
  useGetOrderTotalsQuery,
  useGetEventOrdersReportQuery,
} = ordersApi;
export { ordersApi };
