import toast from 'react-hot-toast';

const copyLinkToClipboard = (link: string) => {
  navigator.clipboard
    .writeText(link)
    .then(() => {
      toast.success('Link copiado al portapapeles');
    })
    .catch((err) => {
      console.error('Error al copiar al portapapeles: ', err);
    });
};

export default copyLinkToClipboard;
