import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { MdOutlineEventBusy } from 'react-icons/md';
import { CiSettings } from 'react-icons/ci';
import EventCard from '../components/EventCard';
import { RootState, useGetBusinessByIdQuery } from '../store';
import EventCardSkeleton from '../components/EventCardSkeleton';
import { setSelectedEvent } from '../store/slices/events';
import Button from '../components/Button';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import useGetUserRole from '../hooks/useGetUserRole';
import EventDTO from '../models/event';
import IconInfoCard from '../components/IconInfoCard';
import LoadingText from '../components/LoadingText';
import Checkbox from '../components/Checkbox';
import MenuDropdown from '../components/MenuDropdown';
import { BusinessDTO } from '../models/business';

const AdminEvents: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const dispatch = useDispatch();
  const [authorizedEvents, setAuthorizedEvents] = useState<EventDTO[]>([]);
  const [showInactiveEvents, setShowInactiveEvents] = useState(false);
  const role = useGetUserRole();
  const userData = useSelector((state: RootState) => state.auth.user);

  const { business_id: businessId } = useParams();
  if (!businessId || !+businessId) {
    throw new Error('no business id');
  }

  const { data: business, isLoading } = useGetBusinessByIdQuery(+businessId);

  useEffect(() => {
    const sellerEventsId = userData.user_events.map((e) => e.event_id);
    const baseEvents = business?.events
      ?.filter((e) => (showInactiveEvents ? true : e.active))
      ?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    if (role === 'SELLER') {
      setAuthorizedEvents(
        baseEvents?.filter((e) => sellerEventsId.includes(e.id)) || [],
      );
    } else {
      setAuthorizedEvents(baseEvents || []);
    }
  }, [role, business, showInactiveEvents]);

  const handleEventSelect = (eventId: number) => {
    const event = business?.events?.find((e) => e.id === eventId);
    if (event) {
      dispatch(setSelectedEvent(event));
    }
    navigate(`${eventId}`);
  };

  const dropDownOptions = [
    {
      label: 'Editar usuarios',
      onClick: (b: BusinessDTO) => navigate(`../${b.id}/users`),
    },
    {
      label: 'Configurar Mercado Pago',
      onClick: (b: BusinessDTO) => navigate(`../${b.id}/mercadopago`),
      hidden: role !== 'ROOT' || !business?.has_mp_split,
    },
  ];

  return (
    <div className="p-3">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl mb-3">
          Eventos de{' '}
          <LoadingText text={business?.name || ''} isLoading={isLoading} />
        </h1>
        {business && (
          <MenuDropdown<BusinessDTO>
            options={dropDownOptions}
            data={business}
            icon={<CiSettings className="text-xl" />}
          />
        )}
      </div>
      <Checkbox
        label="Mostrar eventos inactivos"
        onCheckChange={(isChecked) => setShowInactiveEvents(isChecked)}
      />
      <hr className="my-5" />
      {isLoading && <EventCardSkeleton />}
      <div className="flex flex-col items-center">
        {!isLoading
          && authorizedEvents.map((event) => (
            <EventCard
              key={event.id}
              event={event}
              onSelect={handleEventSelect}
            />
          ))}
      </div>
      {!isLoading && authorizedEvents.length === 0 && (
        <IconInfoCard
          title="No hay eventos disponibles"
          info="Todavía no tenés eventos asociados"
          icon={MdOutlineEventBusy}
          iconColor="text-indigo-600"
        />
      )}
      {userData.type === 'ROOT' && (
        <div className="flex flex-col justify-between px-20">
          <Button
            type="submit"
            primary
            className="mb-2"
            onClick={() => navigate('create')}
          >
            Crear nuevo evento
          </Button>
        </div>
      )}
    </div>
  );
};
export default AdminEvents;
