import { OrderItemData } from '../models/order';
import { formatCurrency } from '../utils/shared';
import TitleTextCard from './TitleTextCard';

interface Props {
  items: OrderItemData[];
  customized: boolean;
  hasOpenPrice?: boolean;
}

const OrderSummary: React.FC<Props> = ({ items, customized, hasOpenPrice }) => {
  let ticketCount = 0;
  let extraCount = 0;

  return (
    <div className="flex justify-center items-center w-full">
      <TitleTextCard title="Resumen de compra">
        {!customized && <span className='font-medium mb-3'>{items[0].name} {items[0].last_name} - {items[0].person_id}</span>}
        {items.map((item, index) => {
          if (!item.ticket_extra) {
            ticketCount += 1;
          } else {
            extraCount += 1;
          }
          return (
            <div className={`${customized ? 'mt-3' : ''} text-secondary text-gray-500`} key={index}>
              <div>
                <span className="font-medium">
                  {!item.ticket_extra && <span> Entrada {ticketCount} - {item.ticket.name}</span>}
                  {item.ticket_extra && <span> Extra {extraCount} - {item.ticket.name}</span>}
                </span>{' '}
                {!hasOpenPrice && <span>{formatCurrency(item.ticket.price)}</span>}
              </div>
              {customized && <div>
                {item.name} {item.last_name} - {item.person_id}
              </div>}
            </div>
          );
        })}
      </TitleTextCard>
    </div>
  );
};
export default OrderSummary;
