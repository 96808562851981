export enum PaymentProvider {
  MercadoPago = 'mercadopago',
  Uala = 'uala',
}

export enum PaymentScope {
  Total = 'total',
}

export interface PaymentConfigSchema {
  id: number;
  access_token: string;
  user_id: number;
  token_expires_at: Date;
  scope: PaymentScope;
  refresh_token: string;
  comission_rate: number;
  avantti_rate: number;
  accreditation_time: number;
  effective_comission_rate: number;
  uses_vat: boolean;
  provider: PaymentProvider;
  business_id: number;
}
