import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaLink } from 'react-icons/fa';
import SelectTicketsTable, {
  TicketSelection,
} from '../components/SelectTicketsTable';
import {
  setTicketsSelected, useGetAllTicketsQuery, useReserveTicketsMutation,
} from '../store';
import Button from '../components/Button';
import TicketDTO, { TicketReservation } from '../models/ticket';
import OrdersTableSkeleton from '../components/OrdersTableSkeleton';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';
import useGetUserRole from '../hooks/useGetUserRole';
import IconInfoCard from '../components/IconInfoCard';

const SelectTicketsPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigateWithQuery();
  const location = useLocation();
  const isBackoffice = location.pathname.includes('admin');
  const { event } = useGetSelectedEvent();
  const { data: eventTickets, isLoading: ticketsLoading } = useGetAllTicketsQuery({ businessId: event?.business_id || 0, eventId: +(event?.id || 0) }, { skip: !event });
  const [allTickets, setAllTickets] = useState<TicketDTO[]>([]);
  const [entryTickets, setEntryTickets] = useState<TicketDTO[]>([]);
  const [extraTickets, setExtraTickets] = useState<TicketDTO[]>([]);
  const [warning, setWarning] = useState(false);
  const [ticketSelection, setTicketsSelection] = useState<TicketSelection[]>([]);
  const [extraSelection, setExtraSelection] = useState<TicketSelection[]>([]);
  const [reserveTickets, resultsReserve] = useReserveTicketsMutation();
  const [ticketKey, setTicketKey] = useState(0); // used to reset counter on tickets change
  const role = useGetUserRole({ skip: !isBackoffice });

  useEffect(() => {
    setAllTickets(eventTickets?.filter((t) => (isBackoffice ? t : t.active))?.filter((t) => (((role === 'ADMIN' || role === 'ROOT') && isBackoffice) ? t : !t.is_only_admin)) || []);
    setTicketKey((prevKey) => prevKey + 1);
  }, [eventTickets]);

  useEffect(() => {
    localStorage.removeItem('formItemsData');
  }, []);

  useEffect(() => {
    setEntryTickets(allTickets.filter((t) => !t.is_extra));
    setExtraTickets(allTickets.filter((t) => t.is_extra));
  }, [allTickets]);

  useEffect(() => {
    if (ticketSelection.length > 0 || extraSelection.length > 0) {
      setWarning(false);
    }
  }, [ticketSelection, extraSelection]);

  if (!event) {
    navigate('../../');
    return <div>no event</div>;
  }

  const handleTicketsChange = (updatedSelection: TicketSelection[]) => {
    setTicketsSelection(updatedSelection);
  };

  const handleExtrasChange = (updatedSelection: TicketSelection[]) => {
    setExtraSelection(updatedSelection);
  };

  const handleClickNext = async () => {
    if (ticketSelection.length === 0 && extraSelection.length === 0) {
      return setWarning(true);
    }
    const reservations: TicketReservation[] = ticketSelection.map((ts) => ({ ticket_id: ts.ticket.id, amount: ts.quantity }));
    const result = await reserveTickets({ businessId: event.business_id, eventId: event.id, reservations });
    let errorMsg = 'Ocurrió un error';
    if ('data' in result) {
      if (result.data.data?.valid) {
        dispatch(setTicketsSelected([...ticketSelection, ...extraSelection]));
        return navigate('../entry-data');
      }
      if (result.data.data?.valid === false) {
        errorMsg = 'Las entradas seleccionadas se agotaron';
      }
    } else {
      console.error('error reserving tickets', result);
    }
    toast.error(errorMsg, {
      duration: 7000,
      position: 'bottom-center',
    });
    return '';
  };

  return (
    <div>
      {ticketsLoading && <div className="card border border-gray-200"><OrdersTableSkeleton/></div>}
      {entryTickets.length > 0 && <div className="card border border-gray-200">
        <h1 className="text-lg p-1">Tickets</h1>
        {!ticketsLoading && <SelectTicketsTable
          tickets={entryTickets} // pendiente resetear el contador cuando se agoten
          onTicketsChange={(updatedTicketsSelection) => handleTicketsChange(updatedTicketsSelection)}
          key={ticketKey}
        />}
      </div>}
        {!ticketsLoading && entryTickets.length === 0 && <div className="mt-12">
          <IconInfoCard
            title="Aún no hay entradas creadas"
            info="Aquí aparecerán todas las entradas creadas para el evento"
            icon={FaLink}
            iconColor="text-indigo-600"
          />
        </div>}
      {(!ticketsLoading && extraTickets.length > 0) && <div className="card border border-gray-200 mt-5">
        <h1 className="text-lg p-1">Extras</h1>
         <SelectTicketsTable
          tickets={extraTickets} // pendiente resetear el contador cuando se agoten
          onTicketsChange={(updatedExtrasSelection) => handleExtrasChange(updatedExtrasSelection)}
          key={ticketKey}
        />
      </div>}
      {warning && (
        <div className="text-red-500 text-center mt-2">
          Debes seleccionar al menos una entrada
        </div>
      )}
      <div className="flex flex-col justify-center mt-10">
        <Button
          className="mb-2 h-12"
          primary
          onClick={handleClickNext}
          loading={resultsReserve.isLoading}
        >
          Siguiente
        </Button>
        <Button transparent onClick={() => navigate('../../')}>
          Volver
        </Button>
      </div>
    </div>
  );
};

export default SelectTicketsPage;
